$brandColor: #0c99c9;
$brandColorLight: #d9eff7;
$secondaryColor: #a3050d;
$textColor: #2e2e2e;
// $brandColorRgb: 19, 181, 101;

.smallHeading {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 600;
    color: $textColor;
}

#stripeForm {
    .additionalFormData {
        .inputWrapper {
            padding: 0 2px;
            margin-top: 10px;

            .label {
                font-size: 14px;
            }

            input {
                padding: 0.75rem;
                background-color: #fff;
                border-radius: 5px;
                transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
                border: 1px solid #e6e6e6;
                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
                outline: #93f8ff;
                width: 100%;
                height: 40px;
                color: #3f3f3f;
            }

            .errorText {
                margin: 0;
                padding: 0;
                color: red;
                font-size: 12px;
                font-weight: 600;
                height: 15px;
                margin-bottom: -10px;
            }
        }
    }

    .checkboxWrapper {
        margin-top: 2rem;
        padding-top: 15px;

        a {
            color: rgb(128, 0, 0);
            font-weight: 600;
            text-decoration: underline;
        }
    }

    .submissionWrapper {
        button {
            margin-top: 15px;
        }
    }
}

#testimonials {
    position: relative;
}


.sampleMessageLink {
    color: rgb(163, 5, 13);
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
}

.goButton {
    margin-top: 15px;
    background-color: $brandColor;
    padding: 10px 60px;
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.goButton {
    margin-top: 15px;
    background-color: $brandColor;
    padding: 10px 60px;
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);

    &:disabled {
        opacity: 0.5;
    }
}



.about .container {
    // background-color: transparent !important;
    // padding: 60px 50px;
}

.testimonials .testimonial-item p {
    // background-color: #F7D5A2 !important;
}

.bx {
    // color: rgb(112, 76, 76) !important;
}

.cta {
    background-color: #F7D5A2 !important;
    background: unset !important;
}

.core.cta {
    padding: 20px 0;
    background-color: #ffffff !important;

    .container {
        padding: 20px 60px;
        background-color: #ffffff00 !important;

    }

    p {
        padding-top: 1rem;
        font-size: 1.2rem;
    }

    // transform: translate3d(0px, 0px, 0px)!important;
    // // z-index: 10002!important;
    // // height: 500px!important;
    // // width: 60%!important;
    // background-color: #F7D5A2!important;
    // // background-color: rgb(255, 255, 255)!important;
    // // border-radius: 5px!important;
    // // box-sizing: border-box!important;
    // // box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px;
    // transition: transform 500ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 500ms cubic-bezier(0, 0, 0.25, 1) 0s;
}

.modalWrapper {
    &>div {
        &>div {
            // background-color: $brandColor;
            // background: $brandColor;



            @media (max-width: 768px) {
                &>div:first-child {
                    width: 90% !important;
                    // height: 100% !important;
                }
            }

            @media (max-width: 480px) {
                &>div:first-child {
                    width: 110% !important;
                    height: 100% !important;
                }
            }


        }
    }

    .termsWrapper {
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0px 30px 40px 30px;

        .termsContent {
            @media (max-width: 480px) {
                font-size: 1.2rem;
            }
        }

        .termsActions {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 30px;
            left: 0px;
            width: 100%;

            .agreeBtn {
                background-color: $brandColor;
                color: white;
                padding: 5px 55px;
                border: none;
                margin-right: 15px;

                @media (max-width: 480px) {
                    font-size: 1.2rem;
                }
            }

            .dismissLink {
                a {
                    color: gray !important;
                    text-decoration: none !important;

                    @media (max-width: 480px) {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}



.supportEmail {
    margin-top: 25px;
    margin-bottom: -25px;
    p {
        font-weight: 600;
        font-size: 1rem;
    }
}















.homePage {
    .sectionHeading {
        font-size: 32px;
        font-weight: bold;
        color: $textColor;
        position: relative;
        padding-bottom: 16px;
        margin-bottom: 16px;

        &::after {
            content: "";
            width: 80px;
            height: 4px;
            background-color: $brandColor;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        &.center {
            text-align: center;

            &::after {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        @media (max-width: 991px) {
            font-size: 24px;
        }
    }

    .link {
        color: $brandColor;
        font-weight: 700;
        text-decoration: underline;
    }

    p {
        color: $textColor;
    }


    #header {
        height: auto;
        padding: 16px 0;
        position: sticky;
        top: 0;
        transition: 0.2s;

        &.shrinkHeader {
            padding: 8px 0;
            box-shadow: 0 0 50px rgba(0,0,0,0.1);
        }



        .logo a {
            // font-size: 1.3rem;
            font-weight: 600;
            text-decoration: none;
            color: inherit;
        
            h3 {
                color: $brandColor;
                margin: 0;
                font-weight: bold;
                font-size: 24px;
            }
        
            span {
                display: block;
                font-size: 12px;
                color: #000;
            }
        }



        .navbar {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            align-items: center;
        
            .nav-link {
                padding: 12px;
                color: #000;
                font-size: 14px;
                transform: scale(1);
        
                &:hover {
                    background-color: #efefef;
                    color: #000;
                }
        
                &:active {
                    // background-color: red;
                    padding: 12px!important;
                    transform: scale(0.5);
                }
            }
        }


        @media (max-width: 991px) {
            .logo a {
                text-align: center;

                h3 {
                    font-size: 22px;
                }
            }

            .navbar {
                justify-content: flex-start;
                margin-top: 8px;
                overflow: scroll;

                &::-webkit-scrollbar {
                    width: 0;
                }

                ul {
                    display: flex;
                    justify-content: flex-start;
                    gap: 8px;
    
                    .nav-link {
                        font-size: 12px;
                        padding: 4px 8px;
                        border: 1px solid $brandColor;
                        border-radius: 5px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    // go to top button
    .topButton {
        position: fixed;
        bottom: 60px;
        right: 120px;
        background-color: #000;
        border-radius: 50px;
        width: 50px;
        height: 50px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
        cursor: pointer;
        transition: 0.2s;
        
        &:hover {
            background-color: $brandColor;
        }

        @media (max-width: 480px) {
            bottom: 30px;
            right: 30px;
        }

        @media (max-width: 800px) {
            bottom: 30px;
            right: 30px;
        }
    }


    .about {
        margin: 0;
        padding: 50px 0 100px;

        p {
            font-size: 14px;
        }
        
        .inner {
            display: flex;
            align-items: center;
            gap: 56px;

            .left {
                .bigHeading {
                    font-size: 32px;
                    font-weight: bold;
                    color: $textColor;
                    margin-bottom: 16px;
                    padding-bottom: 8px;
                    position: relative;

                    &::after {
                        content: "";
                        width: 80px;
                        height: 4px;
                        background-color: $brandColor;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }

                    span {
                        color: $brandColor;
                    }
                }

                .smallHeading {
                    margin-bottom: 16px;
                    font-size: 18px;
                    font-weight: 600;
                    color: $textColor;
                }

                p {
                    font-size: 14px;

                    .highlight {
                        background-color: $brandColorLight;
                        padding: 2px 8px;
                        color: $textColor;
                        font-weight: 700;
                    }
                }
            }

            .right {
                flex: 0 0 400px;
                img {
                    width: 400px;
                    height: 400px;
                    object-fit: cover;
                }
            }
        }

        @media (max-width: 991px) {
            padding: 0;
            padding-bottom: 32px;

            .inner {
                display: block;

                .left {
                    margin-bottom: 24px;

                    .bigHeading {
                        font-size: 24px;
                        line-height: 120%;
                        padding-bottom: 16px;
                    }
                    .smallHeading {
                        font-size: 16px;
                        line-height: 120%;
                    }
                }
            }
        }
    }

    .work {
        .inner {
            background-color: $brandColorLight;
            padding: 32px;
            border-left: 5px solid $brandColor;
            border-radius: 6px;

            ul {
                margin: 0;

                li {
                    color: $textColor;
                    font-size: 14px;
                    margin-top: 8px;
                }
            }
        }
    }

    .subscription {
        .reason-subscription {
            font-size: 14px;
        }
    }


    .testimonials {
        padding: 0;

        .testimonial-item {
            p {
                background-color: $brandColorLight;
                border-left: 5px solid $brandColor;
                margin: 0;
                color: $textColor;
                font-size: 14px;
                padding: 24px;
                height: 300px;
                overflow-x: hidden;
                overflow-y: scroll;


                /* width */
                &::-webkit-scrollbar {
                    width: 8px;
                }
                
                /* Track */
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px transparent; 
                    border-radius: 10px;
                }
                
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #606060; 
                    border-radius: 10px;
                }
                
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: $brandColor; 
                }

                i {
                    color: $brandColor;
                }
            }
        }
    }


    .sample-text {
        .textCard {
            padding: 32px;
            background-color: $brandColorLight;
            border-left: 5px solid $brandColor;
            border-radius: 6px;
            margin-bottom: 24px;

            h4 {
                color: $textColor;
            }

            p {
                font-size: 14px;
                color: $textColor;
                margin: 0;
            }
        }
    }


    #footer {
        background-color: $brandColor;
        text-align: center;
        padding: 8px 0;
        color: #fff;
        font-weight: 600;
    }
}